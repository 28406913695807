import { Avatar, Box, Button, CardActions, CardContent, Checkbox, CircularProgress, Grid, IconButton, Stack, Modal,ToggleButton, ToggleButtonGroup, Typography,Dialog,DialogContent, ImageList, ImageListItem, ImageListItemBar, FormControlLabel } from "@mui/material";
import closeIcon from "../assets/images/close.svg";
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { Lock } from '@mui/icons-material'; 
import profileIcon from "../assets/images/profile2.svg";
import Api from "../state/Api"
import PhoneField from '../mui-components/PhoneField'
import Timer from '../mui-components/Timer'
import password from "../assets/images/password.svg";
import company from "../assets/images/company.svg";
import notification from "../assets/images/notification.svg";
import app from "../assets/images/app.svg";
import report from "../assets/images/report.svg";
import calendar from "../assets/images/calendar2.svg";
import access from "../assets/images/lock-access.svg";
import desktop from "../assets/images/desktop.svg";
import logout from "../assets/images/logouticon.svg";
import OtpInput from '../mui-components/OtpInput'
import { useEffect, useState } from "react";
import LabeledTextField from "../mui-components/LabeledTextField";
import { useForm,Controller} from "react-hook-form";
import { EntityV2Store, aguserStore, branchStore, breadCrumbStore, configStore, loginStore, newDashboardState } from "../state/CommonState";
import { CheckBox, Visibility, VisibilityOff } from "@mui/icons-material";
import AguserApi from "../state/AguserApi";
import { AntSwitch } from "../mui-components/AntSwitch";
import { BlockedCheck } from "../mui-components/BlockedCheck";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
// import styled from "@emotion/styled";
import {styled} from "@mui/material/styles";
import Agproducttab from "../components/Agproducttab";
import { bookingInformation } from "../StaticData";
import { Card } from "reactstrap";
import EntityApi from "../state/entity-api";
import UserForm from "../mui-components/UserForm";
import UsersList from "../mui-components/UsersList";
import EntityList from "../mui-components/EntityList";
import BranchList from "../mui-components/BranchList";
import CreditList from "../mui-components/CreditList";
import EntityV2Api from "../state/entities-api";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    AguserType,
    EntityTypeV2
  } from "../../src/state/Types";
import BusinessAvatar from '../assets/images/building.png';
import BusinessIcon from '@mui/icons-material/Business';
import EntityCreationModal from "../mui-components/entityCreationModal";
import { LoaderButton } from "../mui-components/LoaderButton";
import { useSnapshot } from "valtio";
import { Dashboard } from "./Dashboard";
import { ENTITY_STATUS } from "../util/constants";
import MuiToggleButton from "@mui/material/ToggleButton";

let menus = [
    { title: "Profile", icon: profileIcon, component: <ProfilePage />, handler: () => { }, showSave: true },
    { title: "Password", icon: password, showSave: true, component: <ChangePassword /> },
    { title: "Entity", icon: company, showSave: true, component : <Entity/> },
    { title: "Notifications", icon: notification, showSave: true, component : <Notification/> },
    // { title: "App Settings", icon: app, showSave: true, component: <AppSettings /> },
    // { title: "DSR Settings", icon: report, showSave: true, component : <DSRSettings/> },
    // { title: "DSR Scheduler", icon: calendar, showSave: true , component : <DSRScheduler/>},
    { title: "Access Control", icon: access, component: <AccessControlPage />, showSave: false }
]

function Menu({ isActive, item, onClick }) {
    return <Box onClick={() => {
        if (onClick) {
            onClick();
        }
    }} sx={{
        "&:hover": {
            cursor: "pointer"
        },
        fontFamily: "Figtree",
        textAlign: "start",
        wordBreak: "break-word",
        whiteSpace: "normal",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        backgroundColor: isActive ? "#2C358A" : "white",
        width: "100%", borderRadius: "4px",
        color: isActive ? "white" : "#555555",
        "& img": {
            filter: isActive ? 'brightness(0) invert(1)' : "brightness(0) saturate(100%) invert(31%) sepia(4%) saturate(22%) hue-rotate(49deg) brightness(98%) contrast(85%)"
        },
    }}><img src={item.icon} /> <span style={{ paddingLeft: "8px" }}>{item.title}</span></Box>
}



function PasswordText({ control, name, rules, label, placeholder,disabled }) {
    const [show, showPassword] = useState(false)
    return <LabeledTextField disabled={disabled} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} placeholder={placeholder} control={control} InputProps={{
        style: {
            // borderRadius:0
        },
        endAdornment: (
            <IconButton size="small" onClick={() => {
                showPassword((prevState) => !prevState);
            }}>
                {show ? <VisibilityOff /> : <Visibility />
                }                        </IconButton>
        ),
    }} type={show ? "text" : "password"} autoComplete="off" size="small" fullWidth label={label} name={name} rules={rules} />
}

function DeviceDetail({ iscurrentDevice = false, device, onLoggedOut }) {
    return <Box sx={{ display: "flex", flexDirection: "row", borderRadius: "7px", border: "1px solid #E8E8E8", padding: "10px", alignItems: "center", gap: 1 }}>
        <img width="25px" height="25px" src={desktop} />
        <Typography sx={{ fontFamily: "Figtree", marginRight: "auto" }}>{device.device}</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, width: "150px" }}>
            <Box sx={device.login == "true"?{ height: "8px", width: "8px", borderRadius: "50%", backgroundColor: "#71A282" }:{ height: "8px", width: "8px", borderRadius: "50%", backgroundColor: "red"}}></Box>
            <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black" }}>
            {device.login == "true" ? "Active" : "Inactive"}
            </Typography>
        </Box>
        <Box sx={{ width: "100px" }}>
            {(!iscurrentDevice   && device.login == "true") && <Button onClick={() => {
                AguserApi.logout({ token: device.token, email: device.email }, (data) => {
                    if (onLoggedOut) {
                        onLoggedOut();
                    }
                });
                
            }} variant="contained" sx={{
                textTransform: "capitalize",
                height: "30px",
                borderRadius: "5px", backgroundColor: "#2B6ED4", "&:hover": {
                    backgroundColor: "#2B6ED4"
                }
            }} >Log Out</Button>}
        </Box>
    </Box>
}

function CenteredCircularProgress() {
    return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}><CircularProgress /></Box>
}



function AccessControlPage() {
    const [currentDevice, setDevice] = useState();
    const [apiState, setApiState] = useState({ data: null, isLoading: true });
    const [disable, setDisable] = useState(false);
    const getList = () => {
        try {
            setApiState((prev) => ({ ...prev, isLoading: true }));
            AguserApi.devicelist((data) => {
                if (data && data?.length > 0) {
                    let currentToken = localStorage.getItem("devicetoken");
                    if (currentToken) {
                        let currentDevice = data.filter(({ token }) => token === currentToken);
                        setDevice(currentDevice[0]);
                        setApiState({ data: data.filter(({ token }) => token !== currentToken), isLoading: false });
                    }
                }
            })
        } catch (error) {
            setApiState((prev) => ({ ...prev, isLoading: false }));
            setDisable(false);
        }
    }

    useEffect(() => {
        getList();
    }, [])

    const oneDeviceLoggedIn = () => {
        return apiState?.data?.some(device => device.login === "true");
    }

    console.log(oneDeviceLoggedIn(),'oneDeviceLoggedIn');
    return <Box sx={{
        flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px", overflowY: "auto", "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2C358A !important"
        }
    }}>
        <Box sx={{ flex: 1, display: "flex", flex: 1, flexDirection: "column", gap: 3 }}>
            <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>Access Control</Typography>
            {apiState.isLoading ? <CenteredCircularProgress /> : <>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }} >
                    <Typography sx={{ fontFamily: "Figtree", color: "black" }}>This Device:</Typography>
                    <DeviceDetail iscurrentDevice={true} device={currentDevice} />
                </Box>
                {apiState.data && apiState.data?.length > 0 && 
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Typography sx={{ fontFamily: "Figtree", color: "black" }}>Other Devices:</Typography>
                    {apiState.data.map((device) => <DeviceDetail device={device} onLoggedOut={getList} />)}
                    {
                        oneDeviceLoggedIn() && 
                        <>
                        <LoaderButton  size="medium" buttonContent={"Log Out From Other Devices"} startIcon={ disable ? <></> : <img src={logout} />} onClick={() => {
                            let count = 0;
                            setDisable(true);
                            apiState.data.forEach((device) => {
                                AguserApi.logout({ token: device.token, email: device.email }, (data) => {
                                    count++;
                                    if (count === apiState.data?.length) {
                                        getList();
                                    }
                                })
                            })
                        }} enableLoader={disable} sx={{
                            alignSelf: "center", width: "30%", backgroundColor: "#F25B5B", borderRadius: "5px", color: "white", textTransform: "capitalize", "&:hover": {
                                backgroundColor: "#F25B5B"
                            }
                        }} />
                        {/* <Button disabled={disable}  onClick={() => {
                        let count = 0;
                        setDisable(true);
                        apiState.data.forEach((device) => {
                            AguserApi.logout({ token: device.token, email: device.email }, (data) => {
                                count++;
                                if (count === apiState.data?.length) {
                                    getList();
                                }
                            })
                        })
                    }} startIcon={ disable ? <></> : <img src={logout} />} sx={{
                        alignSelf: "center", width: "30%", backgroundColor: "#F25B5B", borderRadius: "5px", color: "white", textTransform: "capitalize", "&:hover": {
                            backgroundColor: "#F25B5B"
                        }
                    }}>{ disable ? <CircularProgress size={25} sx={{color:"white"}} /> :  "Log Out From Other Devices"}</Button> */}
                    </>}
               
                </Box>
                }
            </>}
        </Box>
    </Box>
}


function ChangePassword() {
    console.log(aguserStore.aguserRec,'pass1234');
    const [crctPass, setCrctPass] = useState(false);
    const [compPass, setcompPass] = useState(true);
    const [dataCall, setDataCall] = useState(false);
    const defaultForm = useForm({
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
    });
    const { control, watch, handleSubmit } = defaultForm
    const password = watch('newPassword');
    console.log(!(crctPass),'sttt',crctPass);

    const submitHandler = (data) => {
            Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/comparePassword`,{
                "oldPassword": `${data.oldPassword}`,
                "newPassword":`${data.confirmPassword}`
            },(data1)=>{
                setCrctPass(data1.crctPasswordFlag);
                setcompPass(data1.compPasswordFlag);                
                setDataCall(true)
                // if(data1?.crctPasswordFlag && !data1?.compPasswordFlag){
                // AguserApi.setNewPassword(aguserStore.aguserRec._id, data.confirmPassword,
                //     (data) => configStore.setModalMessage('Password Changed! Please login again with new password.'))
            if(data1?.email) { 
                configStore.setModalMessage('Password Changed! Please login again with new password.')
                loginStore.reinitialize()
                localStorage.removeItem('ag_email')
                localStorage.removeItem('ag_userrec')
                newDashboardState.selectedTab = 0;
                newDashboardState.isProfileSelected = false;
                }
            })
       
    }

    return <CommonSection formSubmit={handleSubmit(submitHandler)} buttonName="Change Password" formRef={defaultForm}><Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>Change Password</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: "14px" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ width: "300px" , display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    <PasswordText rules={{ required: "Old Password is Required",
                    // validate: (value) => (dataCall && !crctPass) || 'Wrong password. Try again'
                }} 
                     control={control} name="oldPassword" label="Old Password" placeholder="Enter your old password" />
                    {
                        (dataCall && !crctPass) && (
                <Typography variant="body2" color="error" style={{color: 'red', fontSize: '13px' }}>
                    Wrong password. Try again
                    </Typography>)}
                </Box>
                <Box sx={{ width: "300px" , display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    <PasswordText rules={{ required: "New Password is Required", pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                message: "Please add all necessary characters to create a safe password. \n. One number\n. One lowercase character\n. One uppercase character\n. At least 8 characters long\n. One symbol."
                },
                minLength: {
                value: 8,
                message: "Password must be at least 8 characters"
            }}} control={control} name="newPassword" label="New Password" placeholder="Enter your new password" />
            {
                        (dataCall && compPass) && (
                <Typography variant="body2" color="error">
                   Old & New Password should not be same.
                    </Typography>)}
                </Box>
                <Box sx={{ width: "300px", display: "flex", flexDirection: "column", justifyContent: "flex-start"  }}>
                    <PasswordText rules={{ required: "Confirm Password is Required", validate: (value) => value === password || 'The confirmed password does not match', }} control={control} name="confirmPassword" label="Confirm Password" placeholder="Re enter your new password" />
                </Box>
            </Box>
        </Box>
    </Box>
    </CommonSection>
}


function Section({ component, buttonName, content }) {
    return <>
        <Box sx={{
            flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px", overflowY: "auto", "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2C358A !important"
            }
        }}>
            {component}
        </Box>
       <Box sx={{display:"flex",flexDirection:"row",height:"6%",justifyContent: content ? "space-between" : "end",alignItems:"center"}}>
        { content}
       <Box sx={{ display: "flex", flexDirection: "row", height: "100%", justifyContent: "end", gap: 2 }}>
            <Button onClick={() => {
                newDashboardState.selectedTab = 0;
                newDashboardState.isProfileSelected = false;
            }} size="small"
                // startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} 
                sx={{ textTransform: "capitalize", backgroundColor: "white" }}>Cancel</Button>
            <Button type="submit" size="small"
                // startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} 
                variant="contained" sx={{ textTransform: "capitalize" }}>{buttonName || "Save Changes"}</Button>
        </Box>
       </Box>
    </>
}


function SettingOptions({ option, label }) {
    return <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
        {option}
        <Typography sx={{ fontFamily: "Figtree", }}>{label}</Typography>
    </Box>
}

function IndividualSettings({ title, content }) {
    return <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Typography sx={{ width: "280px", fontFamily: "Figtree", color: "black", fontSize: "14px" }}>{title}</Typography>
        <span style={{ paddingX: "10px" }}>:</span>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>{content}</Box>
    </Box>
}

function CommonSection({ formSubmit, children, buttonName, content }) {

    return <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", gap: 1 }}>
        {formSubmit ? <form onSubmit={formSubmit} style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", gap: 3 }}>
            <Section content={content} component={children} buttonName={buttonName} />
        </form> : <Section component={children} buttonName={buttonName} content={content} />
        }
    </Box>

}


function CustomSwitch({ isChecked = false }) {
    const [checked, setChecked] = useState(isChecked)
    return <AntSwitch onChange={(e) => {
        setChecked(e.target.checked);
    }} checked={checked} style={{ scale: "1" }} />
}

function DSRScheduler(){
    return <CommonSection>
         <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: 3 }}>
         <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>Scheduler</Typography>
         <IndividualSettings title="Daily Status Report Schedule Timing" content={ <CustomStyledTextField size="small" type="time" />} />
         <IndividualSettings title="Daily Status Report Scheduling Days" content={<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2,flexWrap:"wrap" }}>
                <SettingOptions option={<Checkbox  disableRipple  sx={CustomCheckBoxStyle} />} label="Monday" />
                <SettingOptions option={<Checkbox disableRipple sx={CustomCheckBoxStyle} />} label="Tuesday" />
                <SettingOptions option={<Checkbox disableRipple sx={CustomCheckBoxStyle} />} label="Wednesday" />
                <SettingOptions option={<Checkbox disableRipple sx={CustomCheckBoxStyle} />} label="Thursday" />
                <SettingOptions option={<Checkbox disableRipple sx={CustomCheckBoxStyle} />} label="Friday" />
                <SettingOptions option={<Checkbox disableRipple sx={CustomCheckBoxStyle} />} label="Saturday" />
                <SettingOptions option={<Checkbox disableRipple sx={CustomCheckBoxStyle} />} label="Sunday" />
            </Box>} />
         </Box>
    </CommonSection>
}

const CustomCheckBoxStyle = {
    padding : 0,
    color : "#D4D4D4"
}

function AppSettings() {
    return <CommonSection>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: 3 }}>
            <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>App Settings</Typography>
            <IndividualSettings title="ReveChat on Dashboard" content={<SettingOptions option={<CustomSwitch />} label="On" />} />
            <IndividualSettings title="App Preferences" content={<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
                <SettingOptions option={<Checkbox  disableRipple  sx={CustomCheckBoxStyle} />} label="Cross Border" />
                <SettingOptions option={<Checkbox disableRipple sx={CustomCheckBoxStyle} />} label="Domestic Transport" />
            </Box>} />
            <IndividualSettings title="Communication Modes" content={<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
            <SettingOptions option={<BlockedCheck/>} label="Email" />

            </Box>} />
            <IndividualSettings title="Notification History" content={<SettingOptions option={<CustomSwitch />} label="On" />} />
        </Box>
    </CommonSection>
}

function getDesignation(Entity) {   
    const mainEntity =  aguserStore.aguserRec.mainEntityList?.find(entity => entity.entityId === Entity.entityId);
    const entity = aguserStore.aguserRec.entityList?.find(entity => entity.entityId === Entity.entityId);
    if (mainEntity) {
     return mainEntity.designation
    } else if(entity){
     return entity.designation
    }
  }

  function setDesignation(Entity,designation) {
    aguserStore.aguserRec.mainEntityList?.find(entity => {
        if(entity.entityId === Entity.entityId){
            entity.designation = designation
            return aguserStore.aguserRec.mainEntityList
        }
    });
    aguserStore.aguserRec.entityList?.find(entity => {
        if(entity.entityId === Entity.entityId){
            entity.designation = designation
            return aguserStore.aguserRec.entityList
        }
    });
  }


export function ProfilePage() {
    useSnapshot(aguserStore)
    const Entity = configStore.currentSelectedEntity
    const [result, setResult] = useState(false);
    const [phoneNumberFlag, setPhoneNumberFlag] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [OTP, setOTP] = useState("")
    const [OTPMsg, setOTPMsg] = useState("")
    const [load, setLoad] = useState(false);
    const [verifyFlag, setVerifyFlag] = useState(false);
    const [otpFlag, setOtpFlag] = useState(false);
    const [imgFlag, setImgFlag] = useState(false)
    const [error, setError] = useState("");
    const defaultForm = useForm({
        defaultValues: {
            firstName: aguserStore.aguserRec.firstName,
            lastName: aguserStore.aguserRec.lastName,
            email: aguserStore.aguserRec.email,
            phone: aguserStore.aguserRec.phone,
            password: "",
            confirmPassword: "",
            country: "",
            gst: "",
            designation: getDesignation(Entity),
            linkedin: aguserStore.aguserRec.linkedinProfile
        }
    });

    const { control, handleSubmit } = defaultForm;

    useEffect(() => {

        if(aguserStore.aguserRec.profilePic){
            setImage(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/getfileNew/${aguserStore.aguserRec.profilePic?.pkey}/${aguserStore.aguserRec.profilePic?.fileLink}`)
        }
        const fetchData = async () => {
        await AguserApi.getUser(loginStore.email,(data)=>{  
            let rememberMe = localStorage.getItem("ag_remember_user");
            let userRecString;
            let localUser;
            if (rememberMe === "true") {
                localUser = JSON.parse(localStorage.getItem("ag_userrec"))
            } else {
                localUser = JSON.parse(sessionStorage.getItem("ag_userrec"));
            }

            localUser.aguserRec = data;
            userRecString = JSON.stringify(localUser);
            if (rememberMe === "true") {
                localStorage.setItem("ag_userrec", userRecString)
            } else {
                sessionStorage.setItem("ag_userrec", userRecString);
            }
            console.log('loadddd',aguserStore.aguserRec);
            defaultForm.reset({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.phone,
                password: "",
                confirmPassword: "",
                country: "",
                gst: "",
                designation: getDesignation(Entity),
                linkedin: data.linkedinProfile
            });
        })
    }
    fetchData();
    }, []); 

    const submitHandler = (data) => {
        setLoad(true)
        aguserStore.aguserRec.firstName = data.firstName;
        aguserStore.aguserRec.lastName = data.lastName;
        aguserStore.aguserRec.linkedinProfile = data.linkedin;
        setDesignation(Entity,data.designation)
        if(aguserStore.aguserRec.phonePrefix === '+91' && aguserStore.aguserRec.phone.length>0 && aguserStore.aguserRec.phone === phoneNumber){
            aguserStore.aguserRec.mobileVerified = !aguserStore.aguserRec.mobileVerified
        }
        if(error === ""){
        AguserApi.saveUser((datas) => {
            let rememberMe = localStorage.getItem("ag_remember_user");
            let userRecString;
            let localUser;
            if (rememberMe === "true") {
                localUser = JSON.parse(localStorage.getItem("ag_userrec"))
            } else {
                localUser = JSON.parse(sessionStorage.getItem("ag_userrec"));
            }

            localUser.aguserRec = datas;
            userRecString = JSON.stringify(localUser);
            if (rememberMe === "true") {
                localStorage.setItem("ag_userrec", userRecString)
            } else {
                sessionStorage.setItem("ag_userrec", userRecString);
            }
            setLoad(false)
            setResult(true)
            if(newDashboardState.initialFlag){
                newDashboardState.initialFlag = false 
            }
        },"customer");
        }
    }

    const [image, setImage] = useState(null);




    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

             
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      const fileSize = file.size / (1024 * 1024); 
        if (fileSize > 2) {
            setImgFlag(true)
            return;
        }
      if (file) {
        const reader = new FileReader();
        let url = `${process.env.REACT_APP_API_SERVER}/api/v1/aguser/storeImage`
        const formData = new FormData()
        formData.append("myFile", file)
        formData.append("label", "profilePhoto")
        formData.append("key", "profile")
        fetch(url, {
            method: "POST",
            headers: Api.H1(),
            body: formData,
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.error) configStore.setModalMessage(data.error)
              else {
                aguserStore.aguserRec.profilePic = data.finalObj
                const aguserStoreString = JSON.stringify(aguserStore);
                localStorage.setItem('ag_userrec', aguserStoreString);
              }
            }).catch((error) => {
                console.error(error)
              })
        reader.onload = () => {
            console.log(reader.result,'reader.result');
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  
    function isLinkedInProfile(url) {
        // Regular expression to match LinkedIn profile URLs
        const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]{5,30}\/?$/;
        return regex.test(url);
      }

    const containsAtLeastOneNumber=(inputString) =>{
        return /\d/.test(inputString);
    }

    const verifyCheck = ()=>{
        return ((aguserStore.aguserRec.phone && phoneNumberFlag && aguserStore.aguserRec.phonePrefix === '+91'  && !aguserStore.aguserRec.mobileVerified ) || (aguserStore.aguserRec.phonePrefix == '+91' && !aguserStore.aguserRec.mobileVerified) || aguserStore.aguserRec.phonePrefix !== '+91')
    } 

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#f0f0f0',
        border: '2px solid grey.500',
        boxShadow: 24,
        borderRadius: '16px',
        pt: 2,
        px: 4,
        pb: 3,
      };

    return <CommonSection formSubmit={handleSubmit(submitHandler)}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>Profile</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ width: "300px" }}>
                    <LabeledTextField rules={{ required: "First Name is required",pattern: {
                value: /^[A-Za-z0-9]+$/,
                message: "First Name should only contain alphabetic characters"
                },
                maxLength: {
                value: 50,
                message: "First Name cannot exceed 50 characters"
            }}}  labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="First Name*" control={control} name="firstName" />
                </Box>
                <Box sx={{ width: "300px" }}>
                    <LabeledTextField rules={{pattern: {
                    value: /^[A-Za-z0-9]+$/,
                    message: "Last Name should only contain alphabetic characters"
                    },
                    maxLength: {
                    value: 50,
                    message: "Last Name cannot exceed 50 characters"
             }}} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="Last Name" control={control} name="lastName" />
                </Box>
            <Box sx={{ width: '300px', display: 'flex', alignItems: 'flex-start' }}>
                <PhoneField 
                    callback={(e) => {
                    console.log(e);
                    aguserStore.aguserRec.phone = e?.number;
                    aguserStore.aguserRec.phonePrefix = e?.code;
                    setError(e.error);
                    }}
                    countryCode={aguserStore.aguserRec.phonePrefix}
                    phone={aguserStore.aguserRec.phone}
                    disabled={aguserStore.aguserRec.mobileVerified}
                />
                <Box style={!aguserStore.aguserRec.mobileVerified?{}:{ display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: 'fit-content'}}>
                           {aguserStore.aguserRec.mobileVerified && <label style={{fontSize: '10.3px',color:'green',marginLeft:'-26px',
                    whiteSpace: 'nowrap'}}><DoneAllRoundedIcon sx={{fontSize:'10.3px',color:'green'}}/> Verified Number</label>}
                <Button  sx={!aguserStore.aguserRec.mobileVerified?{
                    marginTop: '20px',
                    marginLeft: '-5px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    height: '38.56px',
                    backgroundColor: 'primary.main', 
                    color: 'white', 
                    '&.Mui-disabled': {
                      backgroundColor: '#5f90d4', 
                      color: 'white',
                    },
                    }:{
                    marginTop: '4px',
                    marginLeft: '-5px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    height: '38.56px',
                    backgroundColor: 'primary.main', 
                    color: 'white', 
                    '&.Mui-disabled': {
                      backgroundColor: '#5f90d4', 
                      color: 'white',
                    },
                    }} variant="contained" component="span" onClick={
                        async ()=>{
                            if(!verifyCheck()){
                                setPhoneNumber(aguserStore.aguserRec.phone)
                            }else{
                                setOtpFlag(true)
                            }
                           if(aguserStore.aguserRec.phone && aguserStore.aguserRec.mobileVerified){
                            aguserStore.aguserRec.mobileVerified = !aguserStore.aguserRec.mobileVerified
                            setPhoneNumberFlag(true) 
                           } else{
                            await AguserApi.GetMobileOTP({userRec:aguserStore.aguserRec},(result)=>{
                                if(result?.data == 'failure' && result.message){
                                    setOTPMsg(result.message)  
                                }
                                setVerifyFlag(true)
                                if(verifyCheck()){
                                    setOtpFlag(false)
                                }
                            })
                           
                           }
                        }} disabled={(aguserStore.aguserRec.phonePrefix !== '+91' || (aguserStore.aguserRec.phonePrefix == '+91' && aguserStore.aguserRec.phone.length!==10) || (phoneNumber === aguserStore.aguserRec.phone && verifyCheck()) || (verifyCheck() && otpFlag))  && true}>
                  { verifyCheck() ?'Verify': <ModeEditRoundedIcon/> }  
                </Button>
                </Box>
        </Box>
            </Box>

            <Modal
          open={verifyFlag}
          //  onClose={() => showBranchForm('list')}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

        <Box sx={{ ...style, width: '27%', marginTop: '-50px' }}>
          <IconButton sx={{ position: 'absolute', top: '-30px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
          <Avatar style={{ backgroundColor: '#266ed4' }}>
            <Lock fontSize="large" sx={{color:'white'}} /></Avatar>
            </IconButton>

                        <Box sx={{ display: "flex", flexDirection: 'column', gap: 2, paddingTop: "32px",paddingBottom:"10px"}}>
                        <Box sx={{ textAlign: 'left', margin: '0 auto', maxWidth: '350px' }}>
                        <Typography sx={{ fontWeight: 'bold', fontFamily: 'Figtree', color: 'black', fontSize: '1.4rem', mb: 1 }}>
                        To Verify Your Mobile Number
                        </Typography>
                        <Typography sx={{ fontWeight: 'normal', fontFamily: 'Figtree', color: 'black', fontSize: '1rem', mb: 1 }}>
                        OTP has been sent to your phone <br />
                        <span style={{ color: '#2C358A' }}>{aguserStore.aguserRec.phonePrefix} {aguserStore.aguserRec.phone}</span> via SMS and WhatsApp
                        </Typography>
                        <OtpInput length={6} callback={(e)=> {
                                                    setOTP(e)
                                                    console.log(e);
                                                    }} errorsmsg={OTPMsg != 'verified' && OTPMsg}/>
                        {!containsAtLeastOneNumber(OTPMsg) &&
                        <div className='mb-4 is-flex is-justify-content-space-between is-align-items-center' style={{fontSize:'12px'}}>
              <p>Didn't get it?</p>
              {/* <p className='fogot_password clr_blue' onClick={getOTP} >Resend Code</p> */}
              <Timer  seconds= {30} minutes={0} resend={async()=> {
                   await AguserApi.GetMobileOTP({userRec:aguserStore.aguserRec})
              }} ></Timer>
              </div>}
              <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
              <Button variant="contained"  sx={{  width: '48%',
              backgroundColor: 'white',
              mr: 1,
              padding: '8.5px 0',
              color: '#2C358A',
              '&:hover': {
                backgroundColor: '#e0e0e0'
              },
              '&.btn_color': {
                backgroundColor: 'white'
              } }} onClick={()=>{
                setVerifyFlag(false)
                setOTPMsg("")
              }}>Cancel</Button>
              <Button variant="contained"  className='btn_color' sx={{ width: '48%', ml: 1,padding: '8.5px 0' }} onClick={async()=>{
                if(OTP.length==6){
                await AguserApi.VerifyMobileOTP({email:aguserStore.aguserRec.email,otp:OTP,phone:aguserStore.aguserRec.phone,phonePrefix:aguserStore.aguserRec.phonePrefix}, (user)=>{
                      if(user.success){
                        setVerifyFlag(false)
                        setOTPMsg(user.msg)
                        if(aguserStore.aguserRec.phone && aguserStore.aguserRec.phonePrefix === '+91'){
                            aguserStore.aguserRec.mobileVerified = !aguserStore.aguserRec.mobileVerified
                        }
                      }else{
                        setOTPMsg(user.msg)
                      }                 
                      }
                    )
                }else{
                    setOTPMsg('Please enter the valid OTP')
                }
                }} disabled={containsAtLeastOneNumber(OTPMsg)}>Submit</Button></Box>
                    </Box>
                        </Box> </Box>
            </Modal>                        
                     
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ flexBasis: "300px" }}>
                    <LabeledTextField rules={{ 
                pattern: {
                    value:/^[A-Za-z .,!?'"()\-\d]+$/,
                    message: "Please enter the valid Designation"
                    },
                maxLength: {
                    value: 100,
                    message: "Designation cannot exceed 100 characters"
                }
        }}   labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="Designation" control={control} name="designation" />
                </Box>
                <Box sx={{ flexBasis: "300px" }}>
                    <LabeledTextField rules={{pattern:{
                        value:/^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]{5,30}\/?$/,
                        message:"Must be a LinkedIn URL"
                    }}} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="LinkedIn Profile" control={control} name="linkedin" />
                </Box>
                <Box sx={{ flexBasis: "300px" }}>
                    <LabeledTextField disabled={true} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px" }} fontSize="14px" label="Email" control={control} name="email" />
                </Box>
                {/* <Box sx={{ flexBasis: "300px" }}></Box> */}
            </Box>
            <Typography sx={{  marginTop:"2%",fontFamily: "Figtree", color: "black", fontSize: "14px" }}>Profile Picture</Typography>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box  style={{ display: 'flex', alignItems: 'center' }}>
                <Box style={{ marginRight: 20 }}>
                    {image &&  (
                    <div>
                        <Avatar alt="Uploaded Image" src={image} style={{ width: 100, height: 100 }} onClick={handleOpen}/>
                        <Dialog open={open}>
                        <DialogContent>
                        <Box 
                onClick={handleClose} 
                sx={{
                    display: "flex",
                    alignSelf: "flex-end",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#2B6ED4",
                    borderRadius: "5px",
                    width: "max-content",
                    padding: "8px",
                    position: "absolute",
                    top: "10px",
                    right: "10px", 
                    "&:hover": {
                        cursor: "pointer"
                    }
                }}>
                <img style={{width: "18px", height: "18px"}} src={closeIcon} />
            </Box>  
                        <img src={image} alt="Uploaded Image" style={{ maxWidth: '100%' }} />
                        </DialogContent>
                        </Dialog>
                        {
                        image.includes('data:image') &&
                        <Typography variant="body2">
                        Size: {(image?.length / 1024).toFixed(2)} KB
                        </Typography>}
                        
                    </div>
                    )
                
                }
                </Box>
               { !image &&
                <Box>
                    <input
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    />
                    <label htmlFor="image-upload">
                    <Button variant="contained" component="span">
                        Browse
                    </Button>
                    </label>
                </Box>}
                {image &&
                <Box
                        onClick={()=> {
                            aguserStore.aguserRec.profilePic=''
                            setImage('')
                        }} 
                        sx={{
                            cursor: 'pointer',
                            marginLeft: '10px'
                        }}
                    >
                        <DeleteIcon />
                    </Box>}
                    { imgFlag &&
                <Typography variant="body2" color="error">
                Please select an image file smaller than 2 MB.
                </Typography>}
            </Box>
            <Box ></Box>
            </Box>
        </Box>
        <NotificationDialog result={result} setResult={setResult} load={load} />
        {newDashboardState.initialFlag  && <Typography variant="h6" sx={{ fontWeight: 'bold',textAlign: 'center' ,width:'100%',paddingTop:'25px'}}>Please update the user profile to procced further.</Typography>}
    </Box>
    </CommonSection>
}

function Entity(){
    const [ manageEntity , setmanageEntity] = useState(false)
    const [ entitiesList , setEntitiesList] = useState([])
    const [ AddNewEntity , setAddNewEntity] = useState(false)
    const [formats , setformats] = useState('entity');
    const [selectedEntity , setselectedEntity] = useState({})

//    let EntityData = EntityV2Store.EntityRec

    const handleformats = (event, value ) => {
        if(value !== null && value !== undefined){
            setformats(value)
        }
    }
    useEffect( ()=>  {
        async  function getEntityData () {
            await getEntitiesData()
          }
      getEntityData()   
    }, [manageEntity , AddNewEntity])


    const getEntitiesData = async () => {
        const response =  await  EntityV2Api.EntityListForUser(loginStore.email , (data)=> {
            setEntitiesList(data.data) 
            loginStore.userRec.companyList=data.data
            let userDatas = localStorage.getItem('ag_userrec')
           let userDataUpd= JSON.parse(userDatas)
           userDataUpd.companyList= data.data 

            // let datas = JSON.stringify({
            //     companyList :data.data,
            //     aguserRec: loginStore.userRec.aguserRec,

            // })
            localStorage.setItem('ag_userrec' , JSON.stringify(userDataUpd)) 
        })
    }
  

    async function selectEntity (x , value) {
     
        await EntityV2Api.viewEntity(x.entityId , (data)=> {
            EntityV2Store.EntityRec.set(new EntityTypeV2() );
            EntityV2Store.EntityRec.set(data);
            setmanageEntity(true)
            setselectedEntity(data)
        })
    }

    function statusColor(x) {
        let colorObj = {}
        if (x.status === ENTITY_STATUS.PENDING) {
          colorObj.outLineColor = 'warning'
          colorObj.backGroundColor = '#ffe0b3'
        } else if (x.status === ENTITY_STATUS.VERIFIED) {
          colorObj.outLineColor = 'success'
          colorObj.backGroundColor = '#b3ffcc'
        } else if (x.status === ENTITY_STATUS.REJECTED) {
          colorObj.outLineColor = 'error'
          colorObj.backGroundColor = '#ffb3b3'
        } else if (x.status === ENTITY_STATUS.INCOMPLETE) {
          colorObj.outLineColor = 'inherit'
          colorObj.backGroundColor = '#fad4d4'
        }else if (x.status === ENTITY_STATUS.DEACTIVATED) {
            colorObj.outLineColor = 'error'
            colorObj.backGroundColor = '#fa7f96'
          }
        return colorObj
      }
   
    

    function isUsersFormat () {
       return formats === 'user' 
    }
    function isBranchFormat () {
        return formats === 'branch' 
     }
     function isEntityFormat () {
        return formats === 'entity' 
     }
     function isCreditFormat () {
        return formats === 'credit' 
     }

     function newEntity () {
        setAddNewEntity(true)
     }

     const ToggleButtonStyd = styled(MuiToggleButton)({
        "&.Mui-selected": {
          color: "white",
          backgroundColor: 'white',
          borderRadius : '30px'
        },"&.Mui-selected:hover": {
        //   color: "white",
          backgroundColor: 'white'
        },
        '&:hover': {
            backgroundColor: 'white' ,
            borderRadius : '30px'
              
        }
      });
    console.log("entitiesList" , entitiesList , formats);

    return <Box  >
{/* //     <Box sx={{ overflowY: "auto", "&::-webkit-scrollbar-thumb": {  backgroundColor: "#2C358A !important"
// }}} > */}
    {!manageEntity ? (
        <Box sx={{
            flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px", overflowY : 'unset !important'
            
        }}>
    
        <Box sx={{display:"flex",flexDirection:"column",gap:2 }}>
        <Typography mb={2} sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>My Entities
        <Box sx={{display:"flex",flexDirection:"row",gap:2 ,maxHeight : "70vh" ,overflowY: "auto", "&::-webkit-scrollbar-thumb": {  backgroundColor: "#2C358A !important"
            }}} width='100%'>
        <Grid container rowSpacing={2} columnSpacing={2} p={3}>
        { entitiesList?.map((x,i) => {
            return <>
         
   <Grid item xs={6}  sm={4} key={i}>
   <Card  >
         
         <CardContent >
         <Stack direction='row' spacing={2}>
         {x.entityLogo?.fileLink?.length > 0 ? <>
          <Avatar src={`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/getfileNew/${x.entityLogo?.pkey}/${x.entityLogo?.fileLink}`}  sx={{border : 1 , borderColor : '#c2bebe' , width: '60px' , height : '60px'}}/>
         </> : <Avatar   sx={{bgcolor : '#60b6eb'  , width: '60px' , height : '60px'  }}>{x.entityName.slice(0,2).toUpperCase()}</Avatar>

         }
         
             {/* <Typography gutterBottom variant='h5' component='div'>
                 React
             </Typography> */}
             <Stack direction='column' spacing={1} width="70%" >
             <Typography variant = 'body2' fontSize={18}  sx={{fontWeight : 'bold'}}>
            {x.entityName}

             </Typography>
             <Typography variant = 'body2' fontSize={16} color='text.secondary'>
             Status : <Button variant='outlined' color={statusColor(x).outLineColor} style={{ backgroundColor: statusColor(x).backGroundColor,  padding: '1% 5% ', border: 'none' , borderRadius: '20px' , fontSize: '14px' , textTransform: 'none'  }} >{x.status.charAt(0).toUpperCase() + x.status.slice(1).toLowerCase() }
             </Button> 

             </Typography>
             </Stack>
             </Stack>

             <Stack direction='column' spacing={2} paddingTop={2} paddingLeft={4}>
             
             <Box direction='row' sx={{ display : 'flex'}} >
             <Typography  fontSize={14} sx={{ fontWeight: 600 , width: '40%'}} >
            Entity Category 

             </Typography>
             <Typography  fontSize={14} sx={{ fontWeight: 600 , width: '5%'}} >
             :
             </Typography>
             <Typography  fontSize={14} paddingLeft={2}>
             {x.entityType}
             </Typography>
             </Box>
             <Box direction='row' sx={{ display : 'flex'}} >
             <Typography  fontSize={14} sx={{fontWeight : 600 , width: '40%'}} >
            Entity Industry 

             </Typography>
             <Typography  fontSize={14} sx={{ fontWeight: 600 , width: '5%'}} >
             :
             </Typography>
             <Typography  fontSize={14} paddingLeft={2}>
             {x.customer.customerType}

             </Typography>
             </Box>
             <Box direction='row' sx={{ display : 'flex'}} >
             <Typography  fontSize={14} sx={{fontWeight : 600 , width: '40%'}} >
            No of Branches 

             </Typography>
             <Typography  fontSize={14} sx={{ fontWeight: 600 , width: '5%'}} >
             : 
             </Typography>
             <Typography  fontSize={14} paddingLeft={2}>
             {x?.branches?.length}

             </Typography>
             </Box>
             <Box direction='row' sx={{ display : 'flex'}} >
             <Typography  fontSize={14} sx={{fontWeight : 600, width: '40%'}} >
            No of Users 

             </Typography>
             <Typography  fontSize={14} sx={{ fontWeight: 600 , width: '5%'}} >
             : 
             </Typography>
             <Typography  fontSize={16} paddingLeft={2}>
             {x?.usersCount}

             </Typography>
             </Box>
             <Box direction='row' sx={{ display : 'flex'}} >
             <Typography  fontSize={14} sx={{fontWeight : 600 , width: '40%'}} >
            Scope of Business 

             </Typography>
             <Typography  fontSize={14} sx={{ fontWeight: 600 , width: '5%'}} >
             :
             </Typography>
             <Typography  fontSize={14} paddingLeft={2}>
             {x.scopeOfBusiness}

             </Typography>
             </Box>
             </Stack>

         </CardContent>
         <CardActions sx={{ justifyContent: "center" , paddingBottom : '4%'}} p={2}>
             <Button variant="contained"  sx={{ width : '90%' , textTransform : 'none' }} onClick ={()=>selectEntity(x)}>Manage Entity</Button>
         </CardActions>
     </Card>
   </Grid>
  
 
  </>
        })}
        <Grid item xs={6}  sm={4} >
   <Card> 
         <CardContent  >
         <Stack direction='row' spacing={2} sx={{ marginLeft : '10%'}} >
         {/* <Avatar  sx={{bgcolor : 'success.light'}}>AW</Avatar>   
                   */}
          {/* <BusinessIcon sx={{ width : '80%' , height : 'auto' , alignItems : 'center', marginLeft : '10%' }}></BusinessIcon>   */}
          <img alt='businessicon'  src={BusinessAvatar} style={{ width: '100%' , height : '100%'}} />       
          </Stack>  
         </CardContent>
         <CardActions sx={{ justifyContent: "center" , paddingBottom : '4%'}} p={2}>
             <Button variant="contained"  sx={{ width : '90%'  }} onClick={()=>newEntity()} >Add New Entity</Button>
         </CardActions>
     </Card>
   </Grid>
        </Grid>
        </Box>
        </Typography>
        </Box>
    </Box>
      
    ): null 

    }
       

        {manageEntity ? (
            <>
            <Box sx={{display:"flex",flexDirection:"row",gap:2 , backgroundColor: 'white' , marginBottom : '2%' , borderRadius: '25px' ,  width: "max-content", padding: '0% 0%',  overflowY : 'unset !important'}} >
        <ToggleButtonGroup aria-label='text-formatting' value={formats} onChange={handleformats} size='small' orientation='horizontal' exclusive>
      <ToggleButtonStyd value ='entity' aria-label='bold' p={0} sx={{border : 'none' , borderRadius: '25px'  }}  disableElevation >
        <Button variant={formats === 'entity' ? 'contained':"text" } sx={{borderRadius: '25px', "&::selected": {  backgroundColor: "white" } }} disableElevation >Entity Details</Button>
      </ToggleButtonStyd>
      {
        selectedEntity.status === ENTITY_STATUS.VERIFIED && ( 
            <ToggleButtonStyd value ='branch' aria-label='italic' p={0} sx={{border : 'none'}}  disableElevation>
      <Button variant={formats === 'branch' ? 'contained':"text"} sx={{borderRadius: '25px'}} disableElevation >Manage Branch</Button>
      </ToggleButtonStyd>
        )
      }
     
      <ToggleButtonStyd value ='user' aria-label='underlined' p={0} sx={{border : 'none'}} disableElevation>
      <Button variant={formats === 'user' ? 'contained':"text"} sx={{borderRadius: '25px'}}  disableElevation>Manage User</Button>
      </ToggleButtonStyd>
      {/* <ToggleButton value ='credit' aria-label='underlined' p={0} sx={{border : 'none'}} disableElevation>
      <Button variant={formats === 'credit' ? 'contained':"text"} sx={{borderRadius: '25px'}} disableElevation   >Manage Credit</Button>
      </ToggleButton> */}
    </ToggleButtonGroup>
  

        </Box>
        {/* <CommonSection formSubmit={handleSubmit(submitHandler)} buttonName="Change Password" formRef={defaultForm}> */}
            <Box sx={{display:"flex",flexDirection:"column",gap:2, width: '100%' , maxHeight : '65vh',
              overflowX:"hidden" , overflowY:"auto",    "&::-webkit-scrollbar-thumb": {  
          backgroundColor: "#2C358A !important" , width: '2px'
      }}}>
            {isEntityFormat() ? <EntityList props={{setmanageEntity  }}></EntityList> : null

            }
            {isBranchFormat() ? <BranchList></BranchList> : null
            }
            {isUsersFormat() ? <UsersList></UsersList> : null
            } 
            {isCreditFormat() ? <CreditList></CreditList> : null
            }
       
        </Box>
        {/* </CommonSection> */}
        </>
        ) : null
        }
        {AddNewEntity ? (

       <EntityCreationModal props={setAddNewEntity}></EntityCreationModal>
        ): null

        }
    </Box>
}

function NotificationDialog({result,setResult,res,load}){
    console.log(res,"res...");
    
    return (    <Dialog open={result}>
         { load ? <CenteredCircularProgress /> : <></>}
        <DialogContent  sx={{backgroundColor:"#F5F5F5",padding:"20px"}} >
        <Box 
    onClick={() => {
        setResult(false);
        newDashboardState.selectedTab = 0;
        newDashboardState.isProfileSelected = false;
        newDashboardState.selectedTransportMode = 0;
        console.log(newDashboardState,'newDashboard..');
    }} 
    sx={{
        display: "flex",
        alignSelf: "flex-end", 
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#2B6ED4",
        borderRadius: "5px",
        width: "max-content",
        padding: "8px",
        position: "absolute", 
        top: "10px", 
        right: "10px", 
        "&:hover": {
            cursor: "pointer"
        }
    }}>
    <img style={{width: "18px", height: "18px"}} src={closeIcon} />
</Box>  

        <Box sx={{display:"flex",flexDirection:'column',gap:2,padding:"25px 20px"}}>
                <Box sx={{display:"flex",flexDirection:'column',alignItems:"center",gap:1}}>     
                <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",color:"black"}}>{res ? "Notification Updated Successfully" : res === undefined ? "Profile Details Updated Successfully" : "Notification not updated, please try again later."}</Typography>
                </Box>
                </Box>
        </DialogContent>
        </Dialog>)
}

function Notification(){
    const [ result , setResult] = useState(false)
    const [load, setLoad] = useState(false);
    const  [res,setRes] = useState(false)

    const defaultForm = useForm({
        defaultValues: {
            whatsapp: false,
            email: false
        }
    });
    const { control,  handleSubmit } = defaultForm

    useEffect(()=>{
        console.log("notification useeffect",aguserStore.aguserRec.notification);
        
        // if(Object.keys(aguserStore.aguserRec.notification).length>0){
         defaultForm.reset({
            whatsapp:aguserStore.aguserRec.notification?.whatsapp,
            email:aguserStore.aguserRec.notification?.email
         })   
        // }
    },[])

    function areObjectsEqual(obj1, obj2) {
        return (
          Object.keys(obj1).length === Object.keys(obj2).length &&
          Object.entries(obj1).every(([key, value]) => obj2[key] === value)
        );
      }

    const submitHandler = (data) => {
        console.log(data,"data123",data !== aguserStore.aguserRec.notification);
        if(!areObjectsEqual(data, aguserStore.aguserRec.notification)){
            setLoad(true)
            Api.post(`${process.env.REACT_APP_API_SERVER}/api/v1/aguser/notification`,
                data,(data1)=>{
                console.log(data1,"ddddd",aguserStore.aguserRec.notification);
                if(data1.status === 'success'){
                    aguserStore.aguserRec.notification.email = data.email
                    aguserStore.aguserRec.notification.whatsapp = data.whatsapp
                    setResult(true)
                    setRes(true)
                }                 
                
            })
        }
        setLoad(false)
        
    }
    return( <CommonSection formSubmit={handleSubmit(submitHandler)}>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>Notifications</Typography>
    

        <Box sx={{ marginTop: 3, display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "14px", color: "black", marginRight: 8 }}>
            Notification Method
          </Typography>
          <Typography sx={{ color: "gray", marginRight: 3 }}>:</Typography>

          <Controller
            name="whatsapp"
            disabled={aguserStore.aguserRec.mobileVerified !== true}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} color="primary" />}
                label="WhatsApp"
                sx={{ marginRight: 4 }}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} color="primary" />}
                label="Email"
              />
            )}
          />
        </Box>

        <Typography sx={{ color: "gray", fontSize: "14px", marginTop: 1.2,marginLeft:27 }}>
            *Choose your notification method to stay updated. You can select WhatsApp, email, or both.
          </Typography>

        <NotificationDialog result={result} setResult={setResult} res={res} load={load} />
        
       </Box></CommonSection>)
}
        
function DsrSettingsList({title,list}){
         return <Box sx={{display:"flex",flexDirection:"column",gap:2}}>
             <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>{title} :</Typography>
             {list.map((option)=><SettingOptions option={option.mandatory ? <BlockedCheck/> : <CheckBox sx={CustomCheckBoxStyle}/> } label={option.milestone} />)} 
         </Box> 
}

function DSRSettings(){
    const [activeProduct, setActiveProduct] = useState("LCL");
    let productList = ['LCL', 'FCL', 'Air'];
    let stuffingList = ["Factory","CFS/Dock"];
    let [stuffingType,setStuffingType] = useState("Factory");
    let [deStuffingType,setDeStuffingType] = useState("Factory");
    return <CommonSection content={<Box sx={{display:"flex",flexDirection:"row",gap:1}}><span>*</span><BlockedCheck/><Typography sx={{fontFamily:"Figtree",color:"black",fontWeight:"bold"}}>Mandatory Field - Uncheckable</Typography></Box>}>
        <Box sx={{width:"20%"}}>
         <Agproducttab productList={productList} isGreyBackground={true}
                        activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
                        </Box>
                        { activeProduct.toLowerCase()==="fcl" && <Box sx={{display:"flex",flexDirection:"row",marginY:"10px",gap:2}}>
                             <Box sx={{display:"flex",flexDirection:"column",width:"180px"}}>
                                <Typography sx={{fontFamily:"Figtree",color:"black"}}>Origin Stuffing</Typography>
                                <Box sx={{width:"100%"}}>
                                  <Agproducttab height="30px" isGreyBackground={true} productList={stuffingList} activeProduct={stuffingType} setActiveProduct={setStuffingType}      />
                                </Box>
                             </Box>
                             <Box sx={{display:"flex",flexDirection:"column",width:"180px"}}>
                                <Typography sx={{fontFamily:"Figtree",color:"black"}}>Destination De-Stuffing</Typography>
                                <Box sx={{width:"100%"}}>
                                  <Agproducttab height="30px" isGreyBackground={true} productList={stuffingList} activeProduct={deStuffingType} setActiveProduct={setDeStuffingType}      />
                                </Box>
                             </Box>
                            </Box>}
       <Typography sx={{ fontFamily: "Figtree", fontWeight: "bold", color: "black", fontSize: "18px" }}>Daily Status Report Milestones - {activeProduct.toUpperCase()}</Typography>
      <Box sx={{display:"flex",flexDirection:"row",width:"100%",padding:"20px 18px"}}>
        <Box sx={{display:"flex",flex:1}}>
            <DsrSettingsList title="Booking Information" list={bookingInformation}/>
        </Box>
        <Box sx={{display:"flex",flex:1}}>
            <DsrSettingsList title="Origin Milestones" list={bookingInformation}/>
        </Box>
        <Box sx={{display:"flex",flex:1}}>
            <DsrSettingsList title="In-transit Milestones" list={bookingInformation}/>
        </Box>
        <Box sx={{display:"flex",flex:1}}>
            <DsrSettingsList title="Destination Milestones" list={bookingInformation}/>
        </Box>
      </Box>
    </CommonSection>
}


export function SettingsPage() {
    const [selectedMenu, setMenu] = useState(0);
    useEffect(() => {
        // breadCrumbStore.onBackArrow = ()=>{
        //     newDashboardState.isProfileSelected = false;
        // }
        return () => {
            // newDashboardState.isProfileSelected = false;
            // newDashboardState.selectedTab = 0;
        }
    }, [])
    return <Box sx={{ minHeight:"550px", display: "flex", flexDirection: "row", width: "100%", gap: 1, flex:1 }} >
        <Box sx={{ backgroundColor: "white", borderRadius: "10px", width: "12%", display: "flex", flexDirection: "column", gap: 1, padding: "12px"  , overflowY: "unset"}}>
            {menus.map((menu, i) => <Menu onClick={() => {
                setMenu(i);
            }} isActive={selectedMenu === i} item={menu} />)}
        </Box>
        <Box sx={{ flex : 1, display: "flex",width:"88%", flexDirection: "column", gap: 1 }}>
            {/* <Box sx={{
                flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px", overflowY: "auto", "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#2C358A !important"
                }
            }}> */}
            {menus[selectedMenu].component}
            {/* </Box> */}
            {/* {menus[selectedMenu].showSave && <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "6%", justifyContent: "end", gap: 2 }}>
                <Button size="small"
                    // startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} 
                    sx={{ textTransform: "capitalize", backgroundColor: "white" }}>Cancel</Button>
                <Button size="small"
                    // startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} 
                    variant="contained" sx={{ textTransform: "capitalize" }}>Save Changes</Button>
            </Box>} */}
        </Box>
    </Box>
}